@import '@tradesolution/iceberg-styles/src/scss/_variables.scss';

.iceberg-navbar-left {
  border-right: 1px solid $GY200;
  transition: all .5s ease-in-out;
  height: calc(100vh - 58px); // 58px is the height of the top navbar
  padding: 0;
  margin-right: 1rem;
  overflow-x: hidden;

}

.iceberg-navbar-left .nav-link {
  position: relative;
  padding-left: 1rem;
  width: 214px;
  height: 50px;
  white-space: nowrap;
}

.iceberg-navbar-left .nav-link.active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-65%);
  height: 25px;
  width: 3px;
  background-color: $B500;
  border-radius: 2px;
}

.accordion-button::after {
  margin-left: 10px;
}